<template>
  <div class="wa__register px-6">
    <divider :title="$_t('title')" :sub-title="$_t('subTitle')" size="login"></divider>

    <v-form @submit.prevent="register" ref="form" v-if="!verifyCodePage">
      <div class="wa__register__form mt-14">
        <v-row>
          <v-col cols="12" class="pb-0">
            <div>
              <v-text-field
                :label="$_t('email')"
                color="SonicSilver"
                v-model="form.email"
                dense
                outlined
                :rules="emailValidation"
              ></v-text-field>
            </div>
          </v-col>
          <template v-if="$_getLocale() == 'en'">
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field
                class="pt-0 mt-0"
                :label="$_t('firstName')"
                color="SonicSilver"
                v-model="form.first_name"
                :rules="enLangNameRule"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field
                class="pt-0 mt-0"
                :label="$_t('lastName')"
                v-model="form.last_name"
                :rules="enLangNameRule"
                color="SonicSilver"
                dense
                outlined
              ></v-text-field>
            </v-col>
          </template>
          <template v-else>
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field
                class="pt-0 mt-0"
                :label="$_t('lastName')"
                v-model="form.last_name_jpn"
                color="SonicSilver"
                :rules="jpLangRule"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field
                class="pt-0 mt-0"
                :label="$_t('firstName')"
                color="SonicSilver"
                v-model="form.first_name_jpn"
                :rules="jpLangRule"
                dense
                outlined
              ></v-text-field>
            </v-col>
          </template>
          <v-col cols="12" class="py-0">
            <v-text-field
              class="pt-0 mt-0"
              :label="$_t('password')"
              autocomplete="new-password"
              v-model="form.password"
              :type="showPass ? 'text' : 'password'"
              @click:append="showPass = !showPass"
              :append-icon="showPass ? 'WMi-eye-off' : 'WMi-eye'"
              hint="password must have a 1 Number and 1 Capital letter"
              dense
              outlined
              color="SonicSilver"
              :rules="password"
            />
          </v-col>
          <v-col cols="12" class="py-0">
            <v-text-field
              class="pt-0 mt-0"
              :label="$_t('passwordAgain')"
              autocomplete="new-password"
              :type="showPassConfig ? 'text' : 'password'"
              @click:append="showPassConfig = !showPassConfig"
              :append-icon="showPassConfig ? 'WMi-eye-off' : 'WMi-eye'"
              hint="password must have a 1 Number and 1 Capital letter"
              v-model="form.password_confirmation"
              color="SonicSilver"
              dense
              outlined
              :rules="password"
            />
          </v-col>
          <!-- <v-col cols="12">
                    <v-row>
                      <v-col cols="8">
                        <div class="text-uppercase prefrred__lang">
                          {{ $_t("prefrredLang") }}
                        </div>
                      </v-col>
                      <v-col cols="4">
                        <div class="d-flex justify-end">
                          <v-item-group>
                            <v-item v-slot="{ active, toggle }" class="mr-2">
                              <block-button
                                :style="
                                  active
                                    ? 'color: var(--color-black) !important;background-color: var(--color-light-gray) !important;'
                                    : ''
                                "
                                text="jp"
                                :class="[
                                  active ? 'active' : '',
                                  'btn-language btn__size__16 mb-2',
                                ]"
                                @click.native="toggle"
                                height="27"
                              ></block-button>
                            </v-item>
                            <v-item v-slot="{ active, toggle }">
                              <block-button
                                :style="
                                  active
                                    ? 'color: var(--color-black) !important;background-color: var(--color-light-gray) !important;'
                                    : ''
                                "
                                text="en"
                                :class="[
                                  active ? 'active' : '',
                                  'btn-language btn__size__16 mb-2',
                                ]"
                                @click.native="toggle"
                                height="27"
                              ></block-button>
                            </v-item>
                          </v-item-group>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col> -->
        </v-row>
        <v-row class="mt-5">
          <v-col cols="6" class="wa__tile--link d-flex justify-start pl-0">
            <router-link
              :to="{
                name: 'login',
                params: { locale: this.$_getlocaleParam() },
              }"
            >
              <block-button
                height="30"
                class="btn-return float-left"
                :text="$_t('returnToLogin')"
                text-mode="text"
                icon="WMi-left-open"
              ></block-button>
            </router-link>
          </v-col>
          <v-col cols="6" class="wa__tile--link d-flex justify-end">
            <block-button
              height="30"
              class="btn-register site__button width-auto bg-transparent"
              :text="$_t('signUp')"
              type="submit"
              :loading="loadingSubmit"
            ></block-button>
          </v-col>
        </v-row>
      </div>
    </v-form>
    <v-form @submit.prevent="verifyEmailCode" v-else>
      <div class="wa__register__form mt-14">
        <v-row>
          <v-col cols="12" class="pb-0">
            <div>
              <v-text-field
                :label="$_t('verifyCode')"
                color="SonicSilver"
                v-model="form.code"
                autofocus
                dense
                outlined
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
        <div class="mt-1">
          <v-row>
            <v-col cols="12" class="py-0">
              <v-divider class="my-0"></v-divider>
              <div class="d-flex align-center justify-space-between">
                <div class="get__code text-uppercase">didn't get the code?</div>
                <div class="send__code d-flex">
                  <div class="text-uppercase" :class="{'cursor-pointer':isActiveResendCode} " @click="resendVerifyPassCode">send again &nbsp;</div>
                  <span v-if="!isActiveResendCode">AFTER {{resendCodeTime}}s</span>
                </div>
              </div>
              <v-divider class="my-0"></v-divider>
            </v-col>
          </v-row>
        </div>
        <div class="mt-4">
          <v-row>
            <v-col cols="6" class="wa__tile--link pl-0">
              <block-button
                height="30"
                class="btn-return float-left"
                :text="$_t('returnToRegistration')"
                text-mode="text"
                icon="WMi-left-open"
                @click.native="toggleVerifyCodePage"
              ></block-button>
            </v-col>
            <v-col cols="6" class="wa__tile--link">
              <block-button
                height="30"
                class="btn-register site__button bg-transparent"
                :loading="loadingVerifyCode"
                :text="$_t('verifyCode')"
                type="submit"
              ></block-button>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-form>
  </div>
</template>
<script>
import { mapActions,mapMutations } from "vuex";
import AuthRepository from "@/abstraction/repository/authRepository";
let authRepository = new AuthRepository();
import {password,jpLangRule,enLangNameRule,emailValidation} from "@/mixins/validate"
const RESEND_CODE_TIME = 60;
export default {
  data: () => ({
    verifyCodePage: false,
    showPass: false,
    showPassConfig: false,
    loadingSubmit: false,
    loadingVerifyCode: false,
    resendCodeInterval: null,
    resendCodeTime: 0,
    isActiveResendCode : false,
  }),
  props: {
    form: {
      type: Object,
    },
  },
  methods: {
    ...mapActions("auth", ["verifyEmail"]),
    ...mapMutations('user', ['SET_MY_INFO']),
    toggleVerifyCodePage() {
      this.verifyCodePage = !this.verifyCodePage;
      if(this.verifyCodePage) {this.resendCodeTimer()}
    },
    resendCodeTimer(){
      this.resendCodeTime = RESEND_CODE_TIME;
        this.isActiveResendCode = false;
      clearInterval(this.resendCodeInterval);
      this.resendCodeInterval = setInterval(() => {
        if(--this.resendCodeTime === 0){
          clearInterval(this.resendCodeInterval);
          this.isActiveResendCode = true;
        }
      }, 1000);
    },
    async register() {
      try {
        this.loadingSubmit = true;
        if (!this.$refs.form.validate()) return;
        const response = await authRepository.register(this.form);
        if (response) {
          this.toggleVerifyCodePage();
        }
      } catch (e) {
        return e;
      } finally {
        this.loadingSubmit = false;
      }
    },
    async verifyEmailCode() {
      try {
        this.loadingVerifyCode = true;
        const {user} = await this.verifyEmail(this.form);
        this.SET_MY_INFO(user)
        this.$router.push({
          name: "modelRegistration",
          params: { locale: this.$_getlocaleParam() },
        });
      } catch (e) {
        return e;
      } finally {
        this.loadingVerifyCode = false;
      }
    },
    async resendVerifyPassCode(){
      try {
        if(this.isActiveResendCode){
          await authRepository.registerResendCode(this.form);
          this.resendCodeTimer()
        }
      } catch (e) {
        return e;
      }
    },
  },
  computed: {
    password,
    jpLangRule,
    enLangNameRule,
    emailValidation
  },
  destroyed(){
    clearInterval(this.resendCodeInterval);
  }
};
</script>
<style scoped>
.cursor-pointer{
  cursor:pointer;
}
</style>
